.obituaries {
  display: flex;
  flex-wrap: wrap;

  .obituary {
    display: inline-block;
    width: 50%;
    padding: 16px;

    @media (max-width: 900px) { width: 100%; }
  }

  .surface {
    width: 100%;
    margin: 0;

    .image {
      display: flex;
      justify-content: center;

      img {
        display: block;
        margin: 16px;
        width: 100%;
        max-width: 250px;
      }
    }
  }
}
